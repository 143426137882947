import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SectionTitleTwo from "components/section-title/SectionTitleTwo";
import IconBoxItem from "components/icon-box/IconBoxItemHowWeWork";
    import services from "data/icon-box/IconBoxHowWeWork.json";

const ServiceOne = () => {
    return (
        <div className="bk-whatwedo-area section-ptb-xl " id="howwework">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Col xs={12}>
                            <SectionTitleTwo
                                title={"HOW WE WORK"}
                                heading={"We focus on four pillars to ensure our value proposition sets our customers for success "}
                                center={true}
                            />
                        </Col>
                    </Col>
                </Row>

                <Row className="mt--70 mt_sm--20 mt_md--30 howWeWorkWrap">
                    {services.map(service => (
                        <Col xs={12} md={6} lg={3} key={service.id} className="columnHowWeWork">
                            <IconBoxItem
                                data={service}
                                border={true}
                                padding={true}
                                showBtn={false}
                            />
                        <span className="servicesDevider"> </span>
                        </Col>

                        
                    ))}
                    
                </Row>
                <Row className="mt--70 mt_sm--20 mt_md--30">
                    <Col xs={12} md={12} lg={12} className="columnWhatwedo">
                         {/*<img src={mapImg} alt="headquarters"/>*/}
                    </Col>
                    
                </Row>

            </Container>
        </div>
    );
};

export default ServiceOne;
