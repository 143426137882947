import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import brook from "data/brook.json";
import CookieConsent from "react-cookie-consent";

import Footer from "container/footer/FooterTwo";
import Header from "components/header/HeaderOne";
import Blogs from "container/blog/homes/BlogOne";
import LayoutDefault from "layouts/LayoutDefault";
import FunFact from "container/funfact/FunfactOne";
import WhatWedo from "container/whatwedo/WhatwedoOne";
import HowWeWork from "container/howwework/HowWeWork";
import Contact from "container/contact/ContactOne";
import Services from "container/service/ServiceOne";
import Endtoend from "container/entoend/EndtoendOne";
import Team from "container/team-member/homes/TeamOne";
import HeroSlider from "container/slider/SliderBusiness";
import Portfolios from "container/portfolio/homes/PortfolioOne";
import ClientLogo from "container/client-logo/homes/ClientLogoFour";
import Testimonial from "container/testimonial/homes/TestimonialOne";




const HomeBusiness = () => {
    return (
        <Fragment>
            <Helmet>
                <title>{`${brook.title}`}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2">
                <Header/>
                <HeroSlider/>
                <Services/>
                <FunFact/>
                <WhatWedo/>
                <HowWeWork/>
                <Endtoend/>
                <Portfolios/>
                <ClientLogo
                    arrows={false}
                    dots={false}
                    variant={'brand-style--1'}
                />      
                <Testimonial/>
                <Team/>
                <Contact/>
                <Footer/>
               {/* 
                
                
                

                <Blogs/>
                
                
                */}
                
            </LayoutDefault>

        </Fragment>
    );
};

export default HomeBusiness;