import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import SectionTitleTwo from "components/section-title/SectionTitleTwo";

const ServiceOne = () => {
    return (
        <div className="bk-whatwedo-area section-ptb-xl " id="whatwedo">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Col xs={12}>
                            <SectionTitleTwo
                                title={"WHAT WE DO"}
                                heading={"We provide leading supply chain expertise in the following industries"}
                                center={true}
                            />
                        </Col>
                    </Col>
                </Row>

                <Row className="mt--70 mt_sm--20 mt_md--30">
                    <Col xs={12} md={12} lg={12} className="columnWhatwedo">
                        <div className="columnWhatwedoWrap"> </div>
                    </Col>
                    
                </Row>

            </Container>
        </div>
    );
};

export default ServiceOne;
