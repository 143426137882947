import React from 'react';
import shape1 from 'assets/img/team/shape/team-shape-1.png'
import shape2 from 'assets/img/team/shape/team-shape-2.png'
import shape3 from 'assets/img/team/shape/team-shape-3.png'

const TeamMemberItemOne = ({data}) => {
    return (
        <div className="team team_style--1">
            <div className="row image-wrap">
                <div className="col-lg-7 col-md-6 col-sm-6 thumb">

                    <div className="info infoTeamTop">
                        <h6 className="name">{data.name}</h6>
                        <span className="position">{data.designation}</span>
                    </div>                
                    <img src={require('assets/img/team/team-1/' + data.thumb)} alt={data.name}/>
                            
                    {data.socials.map(social => (       
                        <a target="_blank" key={social.id} className="hint--bounce hint--top hint--primary IconLinkedin"
                            href={`https://${social.media}.com/${social.username}`}
                            aria-label={social.media}
                        >
                        <i className={`fa fa-${social.media}`}/>
                        </a>
                        ))}

                        <div className="info infoTeamBottom">
                            <h6 className="name">{data.name}</h6>
                            <span className="position">{data.designation}</span>
                             <div className="lineDivider"> </div>
                        </div>                        
                </div>
                <div className="col-lg-5 col-sm-6 teamDescription">
                    <p> {data.description}</p>
                    <p className="downInfo"> {data.description2}</p>

                </div>

            </div>


            
        </div>
    );
};

export default TeamMemberItemOne;