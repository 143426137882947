import React from 'react';
import HeaderContainer from "container/header/HeaderContainerOne";
import Logo from "./elements/logo";
import Navbar from "./elements/navbar/Navbar";
import HeaderConfig from "./elements/config/HeaderConfigOne";

import logo from 'assets/img/logo/goikid_logo.png'

const HeaderOne = () => {
    return (
        <HeaderContainer
            transparent={true}
            sticky={true}
            logoVersion={'light'}
        >
            <div className="header-left flex-20">
                <Logo logo={logo}/>
            </div>

            <div className="header-flex-right flex-80">
                <Navbar/>
                <HeaderConfig/>
            </div>


                                        <div className="FooterPopU FooterPopU2">
                                            <section class="av_textblock_section av-l2g5nhdv-641303869fc14b0aa81aad87581ae71d " itemscope="itemscope" itemtype="https://schema.org/CreativeWork"><div class="avia_textblock " itemprop="text"><h1>LEGAL WARNING</h1>
                                            <ol>
                                            <li><strong><u>USE CONDITIONS</u></strong></li>
                                            </ol>
                                            <p>In compliance with Law 34/2002 of 11 July, services of information society and electronic commerce, <strong>GOIKID CONSULTING SL,</strong> informs you that owns the website: <strong>https://goikid.com/. </strong>In agreement with the requirements of Article 10 of the said Act, <strong>GOIKID CONSULTING SL</strong>, reports the following data: the holder of this website is <strong>GOIKID CONSULTING SL</strong><strong>, </strong>CIF: <strong>B66547605,</strong> and Register of <strong>BARCELONA</strong><strong>,</strong> Volume: <strong>44879</strong>. Page: <strong>153, S8</strong> Sheet: <strong>B-470156</strong>, and registered office: <strong>AV. MERIDIANA 326, ENTR.. </strong>Code: <strong>08027</strong>. Location: <strong>BARCELONA</strong>. Province: <strong>BARCELONA.</strong> The email address to contact the company is: <u>iban.pagalday@goikid.com</u></p>
                                            <ol start="2">
                                            <li><strong><u>USER AND RESPONSIBILITIES</u></strong></li>
                                            </ol>
                                            <p>The navigation by access and use of the website and all the subdomains and directories included under the same domain, as well as the services or contents that can be obtained through it and all the own domain portals under the ownership of <strong>GOIKID CONSULTING SL</strong>, grants the condition of User, by which they are accepted, when browsing the website of GOIKID CONSULTING SL, all the conditions of use established here without damaging the corresponding legal case.</p>
                                            <p>Therefore, if you do not agree with the considerations detailed in this Legal Notice, please do not use the Portal, since any use you make of it or the services and contents included in it will imply the acceptance of the legal terms included in this text.</p>

                                            <p>The website of <strong>GOIKID CONSULTING SL</strong> provides a great diversity of information, services and data. The User assumes responsibility for the correct use of the websites. This responsibility will extend to: The veracity and legality of the information provided by the User in the registration and contact forms to access certain contents or services offered in the website.</p>

                                            <p>The use of the information, services and data offered by <strong>GOIKID CONSULTING SL</strong>, will not go against the provisions of these clauses, the Law, morality, good customs or public order that may involve any injury to the rights of third parties or the operation of the websites.</p>

                                            <p>The use of information, services and data offered by <strong>GOIKID CONSULTING SL</strong>, will not go against the provisions of these conditions, law, morals, good customs or public that may involve any injury to the rights of third parties or the operation of the websites.</p>

                                            <p>To this end, the User will refrain from using any of the contents for illegal and prohibited purposes in this text, harmful to the rights and interests of third parties, or that in any way may damage, disable, overload, deteriorate or prevent the normal use of the contents by other Users or any Internet User (hardware and software).</p>

                                            <p>Users shall be liable for damages of any kind that the company owns the site may suffer directly or indirectly as a result of breach of any of the obligations arising from the use of this Website and policy privacy.</p>

                                            <p>In particular, and merely indicative and not exhaustive, the User agrees not to transmit, distribute or make available to third parties information, data, content, messages, graphics, 
                                            drawings, sound and / or images, photographs included in this webite.</p>
                                            <ol start="3">
                                            <li><strong><u>ACCESS TO USER REVIEWS</u></strong></li>
                                            </ol>
                                            <p>In compliance with Royal Legislative Decree-24/2021, of November 2, which modifies the revised text of the General Law for the Defense of Consumers and Users, approved by Royal Legislative Decree 1/2007.</p>

                                            <p>Article 20.4, the commercial practices in which a web entrepreneur facilitates access to consumer and user reviews of goods and services, must contain information about how this web entrepreneur guarantees or not that these published reviews have been made by users or customers who have actually used or purchased the good or service. For this purpose, the employer must provide clear information to consumers and users of how these reviews are processed.</p>

                                            <p><strong>USERS</strong> have access to write reviews of the activity and services of this website through an explicit form for this and, by accepting this Privacy Policy, they agree to share their data and personal image.</p>

                                            <p>The posted content, along with your name and profile photo, may also appear on third-party websites that use Google embeddable widgets or the Google Maps Platform APP, and others.</p>

                                            <p><strong>If you do not want to share your experience, images, or your location, do not use this service to publish content.</strong></p>

                                            <h1>PRIVACY POLICY</h1>
                                            <ol>
                                            <li><strong><u>POLICY LINKS AND DISCLAIMERS</u></strong></li>
                                            </ol>
                                            <p><strong>GOIKID CONSULTING SL</strong> is not responsible for the content of the web pages that the User can access through the links established on its websites and declares that in no case will examine or exercise any type of control over the content of other web pages. Likewise, does not guarantee the technical availability, accuracy, veracity, validity or legality of pages outside its property that can be accessed through the links of this website.</p>

                                            <p><strong>GOIKID CONSULTING SL</strong> declares that it has taken all the necessary measures to avoid any damage that may arise from browsing its web pages. Consequently, <strong>GOIKID CONSULTING SL</strong> is not responsible, in any case, for any damages that the User may suffer due to Internet browsing.</p>

                                            <p><strong>GOIKID CONSULTING SL</strong> is not responsible for damages of any kind caused to the User by errors or disconnections in the telecommunications networks that may produce the suspension, cancellation or interruption of the service of the web during the provision of the same or prior.</p>

                                            <p>Access to the website <a href="https://www.goikid.com/" target="_blank"><strong>https://www.goikid.com/</strong></a> does not imply the obligation of <strong>GOIKID CONSULTING SL</strong> to control the absence of viruses or any other malicious computer element. The User is responsible, in any case, for the availability of adequate tools for the detection, disinfection and protection against harmful computer programs.</p>

                                            <p><strong>GOIKID CONSULTING SL</strong> is not responsible for possible damages caused to computer equipment, documents and/or files of Users or third parties during the provision of the service on this website.</p>
                                            <ol start="2">
                                            <li><strong><u>ADVERTISING</u></strong></li>
                                            </ol>
                                            <p>The website <a href="https://www.goikid.com/" target="_blank"><strong>https://www.goikid.com/</strong></a> may host advertising or sponsored content. Advertisers or sponsors are solely responsible for ensuring that the material sent for inclusion on the Website complies with the laws that may be applicable in each case.</p>

                                            <p><strong>GOIKID CONSULTING SL</strong> is not responsible for any error, inaccuracy or irregularity that the advertising content or the sponsors of this website may contain.</p>
                                            <ol start="3">
                                            <li><strong><u>MODIFICATIONS</u></strong></li>
                                            </ol>
                                            <p><strong>GOIKID CONSULTING SL</strong> reserves the right to make the modifications it deems appropriate in the content of its websites, both in terms of the contents and their conditions of use, or by the general conditions of contracting, without prior notice. These modifications may be made through their websites in any way permissible by law and will be mandatory during the time they are published on the website and until they are modified by any subsequent ones.</p>
                                            <ol start="4">
                                            <li><strong><u>INTELLECTUAL PROPERTY </u></strong></li>
                                            </ol>
                                            <p>The intellectual and industrial property rights derived from all the texts, images, as well as the means and forms of presentation and assembly of its pages belong, by themselves or as an assignee to <strong>GOIKID CONSULTING SL</strong>. They will be, therefore, works protected as intellectual property by the Spanish legal system and both Spanish and Community regulations in this area, as well as international treaties related to the matter and signed by Spain, may be applied to them: Royal Decree-Law 1/1996 and its last modification of 3/30th/2023; Directive (EU) 2019/790 of the European Parliament and of the Council of April 17th, 2019 about copyright and related rights in the Digital Single Market.</p>

                                            <p>All rights reserved. In compliance with the Intellectual Property Law, the reproduction, distribution, public communication and use of all or part of the contents of the web pages is expressly prohibited without the explicit consent of <strong>GOIKID CONSULTING SL</strong>.</p>

                                            <p>Also, <strong>GOIKID CONSULTING SL</strong> reserves the right to file civil or criminal actions that it deems appropriate for improper use of its web pages and content or for breach of these conditions.</p>
                                            <ol start="5">
                                            <li><strong><u>LEGAL ACTIONS, APPLICABLE LEGISLATION AND JURISDICTION</u></strong></li>
                                            </ol>
                                            <p>The relationship between the user and <strong>GOIKID CONSULTING SL</strong> will be governed by current Spanish regulations. The courts or tribunals of the Judicial Party of <strong>BARCELONA</strong> will be competent to decide any dispute that may arise between the user and <strong>GOIKID CONSULTING SL</strong>.</p>

                                            <ol start="6">
                                            <li><strong><u>USE OF COOKIES</u></strong></li>
                                            </ol>
                                            <p><strong>GOIKID CONSULTING SL</strong> uses cookies and other similar technologies that store and retrieve information while browsing. These technologies can have several purposes, such as recognizing a user and obtaining information about their browsing habits. The specific uses we make of these technologies are described in the information contained in the <a href="link-to-cookies-policy" target="_blank"><strong>Cookies Policy</strong></a>.</p>

                                            <p>According to the entity’s data protection policy, <strong>GOIKID CONSULTING SL</strong> informs that cookies are associated with an anonymous user and its computer and do not provide the user’s personal data by themselves.</p>

                                            <p>Our Cookies Policy informs the user about the term these cookies will remain active on its device and if any third party can access the information they store. That is why the 3rd paragraph of Article 5th of the Directive 2002/58 was amended by the Directive 2009/136, and now by the AEPD Guide.</p>

                                            <p>The latest AEPD’s Guide about the use of cookies, of July 2023, incorporates the criterion of the European Committee on Data Protection (CEPD) and states that the options of accepting or rejecting cookies must be at the same level, without any option being more complicated than the other. In this sense, the user must be able to accept or reject explicitly the use of cookies and receive more information about them. In addition, the User has the possibility of configuring his browser in order to be informed about the reception of cookies, being able, if he wishes so, to prevent their installation on his hard drive.</p>
                                            <ol start="7">
                                            <li><strong><u>PRIVACY POLICY</u></strong></li>
                                            </ol>
                                            <p>In accordance with the provisions of the General Regulation-EU-2016/679; the LOPDGDD-3/2018, about Guarantee of Digital Rights; the Law 34/2002, and its actualization of 05/09/2023, about Services of the Society of Information and Electronic Commerce; and the Law 11/2022 General of Telecommunications, <strong>GOIKID CONSULTING SL</strong> informs users of its websites that we are obliged to keep professional secrecy about the personal data that the entity collects through the registration or contact forms of its pages. This obligation will continue even after we have completed our commercial or contractual relationship, and in no case we will be able to make public the personal data of visitors and customers of the web without their consent.</p>

                                            <p><strong>Minor’s consent:</strong> According to articles 6.1.f and 8.1 of the GDPR-EU-2016/679, and the article 84th (protection of minors on Internet) of the LOPD-3/2018, about Guarantee of Digital Rights, when the interested party is a minor under 16 years of age will not be able to give his consent for an online commerce to collect his personal data. In that case, such processing shall be lawful only if that consent is given or authorised by the holder of parental responsibility over the child. In this sense, the online store must have the means to obtain the parents’ or guardians’ explicit consent, for example, by sending an email addressed to one of them that contains a link to an electronic form.</p>

                                            <p><strong>Data subject’s consent:</strong> In accordance with the provisions of Law 34/2002, about Services of the Information Society and Electronic Commerce in its article 21st, and the GDPR-EU-2016/679, articles 5th, 6th, 7th and 13th, we request your consent to send you advertising or informative communications about our entity which we consider may be of interest to you, by email or by any other equivalent electronic means. This consent will be granted or not with the acceptance of this Privacy Policy by a check box set at the bottom of the registration and / or contact form.</p>

                                            <p>These data will be entered in an automated file under the responsibility of the administrator of the website of <strong>GOIKID CONSULTING SL</strong>, with the purpose of being able to facilitate, speed up and fulfill the commitments established between both parties. Also, <strong>GOIKID CONSULTING SL</strong>, informs of the possibility of exercising the access rights, which allows the web user, to know what personal data the administrator of this page has and in that case he will answer within 30 days, provided that he keeps the data; rectification, which allows correcting errors, modify data that are inaccurate or incomplete and ensure the accuracy of the information; opposition, that may request and ensure that it does not carry out data processing, deletion that allows the deletion of inappropriate or excessive data, limitation, by the that you can request that the processing of your data be limited when you have exercised your right to rectification of your personal data, and portability, so that the user can obtain a copy of the personal data that you have provided on the web in order to be able to transmit them to other services, these rights may be exercised by any means that leaves a record of your shipment and its receipt to the address: <a href="mailto:iban.pagalday@goikid.com"><strong>iban.pagalday@goikid.com</strong></a>, providing a photocopy of the ID or alternative documentation that proves your identity. Users have the right to submit claims to the Control Authority, AEPD, ACPD i AVPD.</p>

                                            <p>The sending of your data through the forms on our website will necessarily be subject to having read / accepted the Privacy Policy, by a mandatory check box at the bottom of the form.</p>
                                                                                   
                                            <ol start="8">
                                            <li><strong><u>LSSI-CE AND DATA PROTECTION CERTIFICATES</u></strong></li>
                                            </ol>
                                            <p>For the adaptation of the web <a href="https://www.goikid.com/" target="_blank"><strong>https://www.goikid.com/</strong></a>, <strong>GOIKID CONSULTING SL</strong> has been advised about the regulations of data protection and electronic commerce by <strong>GRUP QUALIA</strong>. For that reason, <strong>GOIKID CONSULTING SL</strong> has been given a digital certificate to expose on their website.</p>

                                            <p><strong>&nbsp;Date of edition:</strong> 11/21/2022</p>
                                            <p><img  class="alignleft size-full wp-image-1960" src="https://goikid.com/sellito.png" alt="" width="60" height="60" /></p>

                                            </div></section>
                                            <span className="closePopup2"> X CLOSE</span>


                                        </div>







        </HeaderContainer>
    );
};

export default HeaderOne;