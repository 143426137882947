import React, {useState} from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const FunfactItemOne = ({number, title, content, className}) => {
    const [appear, setAppear] = useState(false);

    const visibleHandler = (isVisible) => {
        isVisible && setAppear(true);
    };
    return (
        <div className={`number-wrap wow move-up ${className}  col-lg-12 col-md-6 col-12`}>
            <VisibilitySensor onChange={(e) => visibleHandler(e)}>
                <span className="count theme-color">
                   <div className="smallUpTo"> up to</div> <CountUp start={appear ? 0 : null} end={number}/>% 
                </span>
            </VisibilitySensor>
            <div className="content  col-lg-12 col-md-6 col-12">
                <h6>{title}</h6>              
            </div>
            <p className="counterUpContentP">{content}</p>
        </div>
    );
};

export default FunfactItemOne;
