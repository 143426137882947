import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import IconBoxItem from "components/icon-box/IconBoxItemOne";
import SectionTitleTwo from "components/section-title/SectionTitleTwo";
import icon01 from 'assets/img/icons/endtoend1.png'
import icon02 from 'assets/img/icons/endtoend2.png'
import icon03 from 'assets/img/icons/endtoend3.png'
import icon04 from 'assets/img/icons/endtoend4.png'
import icon05 from 'assets/img/icons/endtoend5.png'
import number01 from 'assets/img/icons/01.png'
import number02 from 'assets/img/icons/02.png'
import number03 from 'assets/img/icons/03.png'
import number04 from 'assets/img/icons/04.png'
import number05 from 'assets/img/icons/05.png'


const ServiceOne = () => {
    return (
        <div className="bk-service-area section-ptb-xl " id="endtoend">
            <Container>
                <Row>
                    <Col xs={12}>
                        <SectionTitleTwo
                            title={"HANDS ON SUPPORT THROUGH YOUR TRANSFORMATION JOURNEY"}
                            heading={"A partnership approach to deliver each key step of the supply chain"}
                            center={true}
                        />
                    </Col>
                </Row>
                <div className="row endtoendWrap">
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <header>
                            <div className="row headerWrap"> 
                                <div className="col-lg-6 col-md-6 col-6 headerNumber">
                                    <img src={number01} alt="BUSINESS STRATEGY"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 headerIcon">
                                    <img src={icon01} alt="BUSINESS STRATEGY"/>
                                </div>                                
                            </div>
                            <h4> BUSINESS <br /> STRATEGY </h4>
                            <div className="arrowBorder"> 
                                <div class="arrowLine"></div>
                                <div className="arrowBlue"> </div>
                            </div>
                        </header>
                        <section>
                            <ul> 
                                <li>What challenges your company strategy needs to address? </li>
                                <li>How do your operations roadmap align to be future proof? </li>
                                <li>How to capture & optimize the related financial benefits?</li>
                            </ul>

                        </section>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <header>
                            <div className="row headerWrap"> 
                                <div className="col-lg-6 col-md-6 col-6 headerNumber">
                                    <img src={number02} alt="OPERATING MODEL"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 headerIcon">
                                    <img src={icon02} alt="OPERATING MODEL"/>
                                </div>                                
                            </div>
                            <h4> Operating Model <br />& Processes  </h4>
                            <div className="arrowBorder"> 
                                <div class="arrowLine"></div>
                                <div className="arrowBlue"> </div>
                            </div>
                        </header>
                        <section>
                            <ul> 
                                <li>What is the best fit operating model to implement? </li>
                                <li>What organization and processes need to be put in place?</li>
                                <li>Which are the KPIs and governances to track?</li>
                            </ul>

                        </section>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <header>
                            <div className="row headerWrap"> 
                                <div className="col-lg-6 col-md-6 col-6 headerNumber">
                                    <img src={number03} alt="change management"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 headerIcon">
                                    <img src={icon03} alt="change management"/>
                                </div>                                
                            </div>
                            <h4> Change<br />Management  </h4>
                            <div className="arrowBorder"> 
                                <div class="arrowLine"></div>
                                <div className="arrowBlue"> </div>
                            </div>
                        </header>
                        <section>
                            <ul> 
                                <li>How to successfully achieve the necessary change within the organization? </li>
                                <li>How to communicate across teams to ensure understanding and involvement in the transformation journey? </li>
                                <li>How to upscale team skillsets on new solutions and processes?</li>
                            </ul>

                        </section>
                    </div>

                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <header>
                            <div className="row headerWrap"> 
                                <div className="col-lg-6 col-md-6 col-6 headerNumber">
                                    <img src={number04} alt="Solutions Implementation"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 headerIcon">
                                    <img src={icon04} alt="Solutions Implementation"/>
                                </div>                                
                            </div>
                            <h4> IT Solutions <br />Implementation  </h4>
                            <div className="arrowBorder"> 
                                <div class="arrowLine"></div>
                                <div className="arrowBlue"> </div>
                            </div>
                        </header>
                        <section>
                            <ul> 
                                <li>What are the best suited applications for your specific needs? </li>
                                <li>How to optimize users' adoption and accelerate the delivery of business benefits by leveraging agile methodologies? </li>
                            </ul>

                        </section>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12">
                        <header>
                            <div className="row headerWrap"> 
                                <div className="col-lg-6 col-md-6 col-6 headerNumber">
                                    <img src={number05} alt="BUSINESS STRATEGY"/>
                                </div>
                                <div className="col-lg-6 col-md-6 col-6 headerIcon">
                                    <img src={icon05} alt="MANAGED SERVICES"/>
                                </div>                                
                            </div>
                            <h4> MANAGED <br /> SERVICES </h4>
                            <div className="arrowBorder"> 
                                <div class="arrowLine"></div>
                                <div className="arrowBlue"> </div>
                            </div>
                        </header>
                        <section>
                            <ul> 
                                <li>What are the core functions of your target operating model that can be supported through a temporary managed service? </li>
                                <li>How to accelerate business implementation through the use of managed services, whilst creating and upskilling your team? </li>
                            </ul>

                        </section>
                    </div>                    







                                                               
                </div>

            </Container>
        </div>
    );
};

export default ServiceOne;
