import React from 'react';
import $ from 'jquery';

$(document).ready(function() {

        $(".post-thumbnail").click(function () {
            //alert ("Hola Mundo"); 
          $(this).parent("div").parent("div").parent(".testimonial").toggleClass("slidedown slideup");
          $(this).parent("div").next("div").toggleClass("hidden showIt");

          //  if ($("#Slider").hasClass("slideup"))
          //    $("#Slider").removeClass("slideup").addClass("slidedown");
          //  else
          //      $("#Slider").removeClass("slidedown").addClass("slideup");
        });
});





const TestimonialItemOne = ({data, classes}) => {
    return (
        <div className={`testimonial slideup testimonial_style--1 ${classes && classes}`}>
            <div className="content">
                <p className="bk_pra heading-font testimonialType">
                    {data.type}
                </p>            
                <p className="bk_pra heading-font quote-wrapper">
                    “{data.quote}”
                </p>
                <div className="testimonial-info">
                    <div className="client-info">
                        <h6>{data.client.name}</h6>
                        <span>{data.client.designation}</span>
                    </div>
                    <div className="post-thumbnail">
                        
                    </div>

                </div>

                <div className="project-background hidden">
                    <h6>{data.projectBackTitle} </h6>
                     <span>{data.projectB}</span>
                     <span>{data.projectB2}</span>

                     <ul className="testimonialist"> 
                        <li>{data.projectList1}</li>
                        <li>{data.projectList2}</li>
                        <li>{data.projectList3}</li>
                        <li>{data.projectList4}</li>
                        <li>{data.projectList5}</li>

                     </ul>
                     <span>{data.projectB3}</span>
                </div>                
            </div>
        </div>
    );
};

export default TestimonialItemOne;