import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import funfacts from '../../data/funfact/funfact-one.json'
import FunfactItem from "components/funfact/FunFactItemOne";
import mapImg from 'assets/img/bg/bg-image-3.png'

const FunFactOne = () => {
    return (
        <div className="bk-number-speaks section-ptb-xl">
            <Container>
                <Row>

                    <Col lg={6}>
                        <h3 className="theme-color h3CounterData">DATA SPEAKS</h3>
                        <div className="row">
                            <div className="col col-lg-5 col-md-12 col-12 number-speaks">

                                <div className=" content wow move-up">
                                    
                                    <h2>We are always ready <br/> for a challenge.</h2>
                                </div>
                            </div>
                            <div className="col col-lg-7 col-md-12 col-12 speakers-number-wrap mt--n70">
                                <div className="row">
                                    {funfacts.map(funfact => (
                                        <FunfactItem
                                            key={funfact.id}
                                            number={funfact.numbers} 
                                            title={funfact.title}
                                            content={funfact.content}
                                        />
                                    ))}
                                </div>
                            </div>   
                        </div>                      
                    </Col>

                    <Col lg={6} className="mapImgContent">
                        <h3 className="theme-color">OUR INTERNATIONAL EXPOSURE</h3>
                        <div class="mapgImgWrap"></div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FunFactOne;
