import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import IconBoxItem from "components/icon-box/IconBoxItemOne";
import SectionTitleTwo from "components/section-title/SectionTitleTwo";
import services from "data/icon-box/IconBoxOne.json";

const ServiceOne = () => {
    return (
        <div className="bk-service-area section-ptb-xl " id="whatweare">
            <Container>
                <Row>
                    <Col xs={12}>
                        <Col xs={12}>
                            <SectionTitleTwo
                                title={"WHO WE ARE"}
                                heading={"We are a high value supply chain consulting boutique, taking on your business transformation journey with you"}
                                center={true}
                            />
                        </Col>
                    </Col>
                </Row>

                <Row className="mt--70 mt_sm--20 mt_md--30">
                    {services.map(service => (
                        <Col xs={12} md={6} lg={3} key={service.id} className="columnServices columnServices1">
                            <IconBoxItem
                                data={service}
                                border={true}
                                padding={true}
                                showBtn={false}
                            />
                        <span className="servicesDevider"> </span>
                        </Col>

                        
                    ))}
                    
                </Row>

            </Container>


            <Container className="coreValuesContainer">
                <Row>
                    <Col xs={12}>
                        <Col xs={12}>
                            <SectionTitleTwo
                                title={"OUR CORE VALUES"}
                                heading={"Our core values define us and guide our actions, reflecting our purpose-driven approach as a company"}
                                center={true}
                            />
                        </Col>
                    </Col>
                </Row>

                <Row className="mt--70 mt_sm--20 mt_md--30">
                    {services.map(service => (
                        <Col xs={12} md={6} lg={3} key={service.id} className="columnServices columnServices2">
                            <IconBoxItem
                                data={service}
                                border={true}
                                padding={true}
                                showBtn={false}
                            />
                        <span className="servicesDevider"> </span>
                        </Col>

                        
                    ))}
                    
                </Row>

            </Container>            
        </div>

    );
};

export default ServiceOne;
