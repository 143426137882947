import WOW from "wowjs";
import React, {useEffect} from 'react';
import ScrollToTop from "./utils/scrollToTop";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";


import HomeBusiness from "./pages/homes/HomeBusiness";




const App = () => {
    useEffect(() => {
        new WOW.WOW({// react wow animation dealy, React router es un enrutador para navegar en la web
            live: false
        }).init();
    }, []);
    return (
        <Router>   
            <ScrollToTop>
                <Switch>
                    <Route
                        exact
                        path={`${process.env.PUBLIC_URL + '/'}`}
                        component={HomeBusiness}
                    />
                    <Route exact component={Error}/>

                    // Portfolio routes
                    


                </Switch>
             
                </ScrollToTop>
                
        </Router>
    );
};

export default App;