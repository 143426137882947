import React from 'react';
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import ClientLogoOne from 'assets/img/client/vodafone.png';
import ClientLogoTwo from 'assets/img/client/rogers.png';
import ClientLogoThree from 'assets/img/client/safari.png';
import ClientLogoFour from 'assets/img/client/a1.png';
import ClientLogoFive from 'assets/img/client/telia.png';
import ClientLogoSix from 'assets/img/client/getinge.jpg';
import ClientLogoSeven from 'assets/img/client/agilent.jpg';
import ClientLogoEight from 'assets/img/client/mint.png';
import ClientLogoNine from 'assets/img/client/seb.png';
import ClientLogoTen from 'assets/img/client/nokian.png';
import ClientLogoEleven from 'assets/img/client/ngny.png';
import ClientLogoTwelve from 'assets/img/client/Aktios.png';
import ClientLogoThirteen from 'assets/img/client/rise.png';
import ClientLogoFourteen from 'assets/img/client/users.png';
import ClientLogoFiveteen from 'assets/img/client/Nexeo.png';
import ClientLogoSixteen from 'assets/img/client/IESE.png';
import ClientLogoSeventeen from 'assets/img/client/gme.png';
import ClientLogoEighteen from 'assets/img/client/romeu.png';
import ClientLogoTwenty from 'assets/img/client/european.png';
import ClientLogoTwentyOne from 'assets/img/client/topthee.png';
import ClientLogoTwentyTwo from 'assets/img/client/e2open.png';
import ClientLogoTwentyThree from 'assets/img/client/b2wise.png';
import ClientLogoTwentyFour from 'assets/img/client/affiliate1.jpg';
import ClientLogoTwentyFive from 'assets/img/client/murciano.jpg';


                                            

const TabOne = () => {
    return (
        <div className="tabs-section section pt--120 pt-md--80 pt-sm--60">
            <Container>
                <Row className="mb--50">
                    <Col xs={12}>
                        <div className="tab">
                            <Tab.Container id="pills-tab" defaultActiveKey="first">
                                <Nav variant="pills" className="mb-3" className="pillsWrapTabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">TELCOS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">HEALTH & LIFE SCIENCES</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">MANUFACTURING/AUTOMOTIVE</Nav.Link>
                                    </Nav.Item>                                    
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">RETAIL</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="sixth">OTHERS</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="seventh">STRATEGIC PARTNERS</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="eight">AFFILIATES</Nav.Link>
                                    </Nav.Item>


                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="https://www.a1.group/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoFour"  src={ClientLogoFour} alt="Telekom Austria Group"/></a>
                                            </li>    

                                            <li> 
                                                <a href="https://www.rogers.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwo"  src={ClientLogoTwo} alt="Rogers"/></a>
                                            </li>
                                            <li> 
                                                <a href="https://www.safaricom.co.ke/personal" target="_blank" rel="noopener noreferrer"><img className="ClientLogoThree"  src={ClientLogoThree} alt="Safari"/></a>
                                            </li>    

                                            <li> 
                                                <a href="https://www.teliacompany.com/en" target="_blank" rel="noopener noreferrer"><img className="ClientLogoFive"  src={ClientLogoFive} alt="Telia Company"/></a>
                                            </li> 
                                            <li> 
                                                <a href="https://www.vodafone.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoOne"  src={ClientLogoOne} alt="Vodafone"/></a>
                                            </li>                                            
                                        </ul>

                                    </Tab.Pane>
                                
                                    <Tab.Pane eventKey="second">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="https://www.agilent.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoSeven"  src={ClientLogoSeven} alt="Agilent"/></a>
                                            </li>                                        
                                            <li> 
                                                <a href="https://www.getinge.com/int/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoSix"  src={ClientLogoSix} alt="Getinge"/></a>
                                            </li>

                                            <li> 
                                                <img className="ClientLogoTwentyOne"  src={ClientLogoTwentyOne} alt="3 Top Life Sciences global companies"/>
                                            </li>   

                                            <li> 
                                                <img className="ClientLogoTwentyFive"  src={ClientLogoTwentyFive} alt="Murciano de Salud"/>
                                            </li>                                             

                                        </ul>   
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="third">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="https://www.groupeseb.com/fr" target="_blank" rel="noopener noreferrer"><img className="ClientLogoNine"  src={ClientLogoNine} alt="Seb"/></a>
                                            </li> 
                                            <li> 
                                                <a href="https://www.themintcompany.com/es/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoEight"  src={ClientLogoEight} alt="Mint"/></a>
                                            </li>


                                            
                                        </ul>  
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="#" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwenty"  src={ClientLogoTwenty} alt="European Luxury Automative Company"/></a>
                                            </li>  
                                            <li> 
                                                <a href="http://www.ngny.tech/es/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoEleven"  src={ClientLogoEleven} alt="NGNY"/></a>
                                            </li>                                                                                    
                                            <li> 
                                                <a href="https://www.nokiantyres.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTen"  src={ClientLogoTen} alt="Nokian"/></a>
                                            </li>
                                        </ul>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="sixth">
                                        <ul className="logoList">

                                            <li> 
                                                <a href="https://www.iese.edu/es/claustro-investigacion/centros-investigacion/crhim-center-research-healthcare-innovation-management/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoSixteen"  src={ClientLogoSixteen} alt="IESE"/></a>
                                            </li> 
                                            <li> 
                                                <a href="https://www.garciamunte.com/en/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoSeventeen"  src={ClientLogoSeventeen} alt="GME"/></a>
                                            </li>
                                            <li> 
                                                <a href="https://romeu.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoEighteen"  src={ClientLogoEighteen} alt="Romeu"/></a>
                                             </li>
                                             <li> 
                                                <a href="https://www.nexeoplastics.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoFiveteen"  src={ClientLogoFiveteen} alt="Nexeo"/></a>
                                            </li>                                            
                                            
                                        </ul>  
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="seventh">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="https://www.aktios.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwelve"  src={ClientLogoTwelve} alt="Aktios"/></a>
                                            </li>
                                            <li> 
                                                <a href="https://b2wise.com/es/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwentyTwo"  src={ClientLogoTwentyThree} alt="E2Open"/></a>
                                            </li>  

                                            <li> 
                                                <a href="https://www.e2open.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwentyTwo"  src={ClientLogoTwentyTwo} alt="E2Open"/></a>
                                            </li>

                                            <li> 
                                                <a href="https://www.rise-x.io/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoThirteen"  src={ClientLogoThirteen} alt="Rise-X"/></a>
                                            </li> 
                                            <li> 
                                                <a href="http://usersloveit.com/wp/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoFourteen"  src={ClientLogoFourteen} alt="Users Love It"/></a>
                                            </li>

                                         
                                        </ul>                         


                                    </Tab.Pane>  

                                    <Tab.Pane eventKey="eight">
                                        <ul className="logoList">
                                            <li> 
                                                <a href="https://www.demanddriveninstitute.com/" target="_blank" rel="noopener noreferrer"><img className="ClientLogoTwentyFour"  src={ClientLogoTwentyFour} alt="Demand Driven Institute"/></a>
                                            </li>
                                         
                                        </ul>                         

                                    </Tab.Pane>                                         
                                                                   
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TabOne;
