import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import SectionTitleTwo from "components/section-title/SectionTitleTwo";
import portfolios from '../../../data/portfolio/portfolio-one.json'
import PortfolioItem from "components/portfolio/PortfolioItemOne";
import portfolioGrafica1 from 'assets/img/portfolio/portfolio-1/square1.png';
import portfolioGrafica2 from 'assets/img/portfolio/portfolio-1/square2.png';
import portfolioGrafica3 from 'assets/img/portfolio/portfolio-1/square3.png';
import portfolioGrafica4 from 'assets/img/portfolio/portfolio-1/square4.png';
import dividerVerticalPortfOne from 'assets/img/portfolio/dividerVerticalPortf.png';
import dividerVerticalPortfTwo from 'assets/img/portfolio/dividerHorPortf.png';


$(document).ready(function() {

    $(".portfolioItem1").click(function () {
            //alert ("Hola Mundo"); 
          $('.detailProjectsWrap .container').addClass("tabShow");     

          var offset = 100;
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".verticalTbs").offset().top - offset
          }, 100);   
          //tab visible

          $('#pills-tab-tabpane-second').removeClass('show');
          $('#pills-tab-tabpane-first').addClass('show');
          $('#pills-tab-tabpane-third').removeClass('show'); 

          $('#pills-tab-tabpane-second').removeClass('active');
          $('#pills-tab-tabpane-first').addClass('active');
          $('#pills-tab-tabpane-third').removeClass('active');


          $('#pills-tab-tab-second, #pills-tab-tab-third').removeClass('active');
          $('#pills-tab-tab-first').addClass('active');          

    });

    $(".portfolioItem2").click(function () {
            //alert ("Hola Mundo"); 
          $('.detailProjectsWrap .container').addClass("tabShow");       
          var offset = 100;
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".verticalTbs").offset().top - offset
          }, 100); 
          //tab visible
        $('#pills-tab-tabpane-first').removeClass('show');
          $('#pills-tab-tabpane-second').addClass('show');
          $('#pills-tab-tabpane-third').removeClass('show');
         
          $('#pills-tab-tabpane-first').removeClass('active');
          $('#pills-tab-tabpane-second').addClass('active');
          $('#pills-tab-tabpane-third').removeClass('active');

          

          $('#pills-tab-tab-first, #pills-tab-tab-third').removeClass('active');
          $('#pills-tab-tab-second').addClass('active');             

    });
    $(".portfolioItem3").click(function () {
            //alert ("Hola Mundo"); 
          $('.detailProjectsWrap .container').addClass("tabShow");       
          var offset = 100;
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".verticalTbs").offset().top - offset
          }, 100); 
          //tab visible

          $('#pills-tab-tabpane-second').removeClass('show');
          $('#pills-tab-tabpane-third').addClass('show');
          $('#pills-tab-tabpane-first').removeClass('show');


          $('#pills-tab-tabpane-second').removeClass('active');
          $('#pills-tab-tabpane-third').addClass('active');
          $('#pills-tab-tabpane-first').removeClass('active');
          

          
          $('#pills-tab-tab-first, #pills-tab-tab-second').removeClass('active');
          $('#pills-tab-tab-third').addClass('active');             

    }); 

    $("#pills-tab-tab-first").click(function () {

          $('#pills-tab-tabpane-second').removeClass('show');
          $('#pills-tab-tabpane-first').addClass('show');
          $('#pills-tab-tabpane-third').removeClass('show'); 

          $('#pills-tab-tabpane-second').removeClass('active');
          $('#pills-tab-tabpane-first').addClass('active');
          $('#pills-tab-tabpane-third').removeClass('active');


          $('#pills-tab-tab-second, #pills-tab-tab-third').removeClass('active');
          $(this).addClass('active');       


    });
    $("#pills-tab-tab-second").click(function () {
            //alert ("Hola Mundo"); 
          $('.detailProjectsWrap .container').addClass("tabShow");       
          var offset = 100;
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".verticalTbs").offset().top - offset
          }, 100); 
          //tab visible
        $('#pills-tab-tabpane-first').removeClass('show');
          $('#pills-tab-tabpane-second').addClass('show');
          $('#pills-tab-tabpane-third').removeClass('show');
         
          $('#pills-tab-tabpane-first').removeClass('active');
          $('#pills-tab-tabpane-second').addClass('active');
          $('#pills-tab-tabpane-third').removeClass('active');

          

          $('#pills-tab-tab-first, #pills-tab-tab-third').removeClass('active');
          $(this).addClass('active');             

    });

    $("#pills-tab-tab-third").click(function () {
            //alert ("Hola Mundo"); 
          $('.detailProjectsWrap .container').addClass("tabShow");       
          var offset = 100;
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".verticalTbs").offset().top - offset
          }, 100); 
          //tab visible

          $('#pills-tab-tabpane-second').removeClass('show');
          $('#pills-tab-tabpane-third').addClass('show');
          $('#pills-tab-tabpane-first').removeClass('show');


          $('#pills-tab-tabpane-second').removeClass('active');
          $('#pills-tab-tabpane-third').addClass('active');
          $('#pills-tab-tabpane-first').removeClass('active');
          

          
          $('#pills-tab-tab-first, #pills-tab-tab-second').removeClass('active');
          $(this).addClass('active');             

    }); 
    $('.closeProjects').click(function(){
        $('.detailProjectsWrap .container').addClass("tabHide");       
        $('.detailProjectsWrap .container').removeClass("tabShow");
          $([document.documentElement, document.body]).animate({
                    scrollTop: $(".bk-portfolio-area").offset().top
          }, 100);            
    });       
});

const PortfolioOne = () => {
    return (

        <div className="bk-portfolio-area" id="projects">


            <div className="black-bg-wrapper section-pt-xl pb--240">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <SectionTitleTwo
                                className={''}
                                center={true}
                                title={"Projects, Clients & Partners"}
                                heading={"A sample of relevant projects delivered"}
                                additionalInfo={"Click on pictures"}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="portfolio-wrapper  container">
                <Row className={"row--0 space_dec--110"}>
                    <Col xl={6} lg={12} md={12} className="portfolio-wrapper-icons">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                <img className="squareImg" src={portfolioGrafica1} alt="LEADING EUROPEAN TELCO"/>
                                <img className="dividerPortfOne dividerPortfTwoHidden" src={dividerVerticalPortfOne} alt=""/>
                                <img className="dividerPortfTwo" src={dividerVerticalPortfTwo} alt=""/>
                            </div>
                            
                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                <img className="squareImg" src={portfolioGrafica2} alt="INTERNATIONAL GLOBAL LOGISTICS HOLDING"/>
                                <img className="dividerPortfTwo" src={dividerVerticalPortfTwo} alt=""/>
                                <img className="dividerPortfTwo dividerPortfTwoHidden" src={dividerVerticalPortfTwo} alt=""/>
                            </div>
                            
                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                <img className="squareImg" src={portfolioGrafica3} alt="LEADING HOME APPLIANCES MANUFACTURER"/>
                                <img className="dividerPortfOne dividerPortfTwoHidden" src={dividerVerticalPortfOne} alt=""/>
                                <img className="dividerPortfTwo " src={dividerVerticalPortfTwo} alt=""/>
                            </div>
                            
                            <div className="col-lg-6 col-md-6 col-sm-12 column">
                                <img className="squareImg" src={portfolioGrafica4} alt="GLOBAL LIFE SCIENCE COMPANY"/>

                            </div>                                                     
                        </div>
                    </Col>                 

                    <Col xl={6} lg={12} md={12} >
                        <Row className={"row--0"}>
                            <Col xs={12}>
                                <PortfolioItem className="portfolioItem1"
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/' + portfolios[1].thumb)}
                                    title={[portfolios[1].title]}
                                />
                                <div className="plusIcon"> </div>
                            </Col>

                            <Col md={6}>
                                <PortfolioItem className="portfolioItem2"
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/' + portfolios[2].thumb)}
                                    title={[portfolios[2].title]}
                                />
                                <div className="plusIcon"> </div>
                            </Col>

                            <Col md={6}>
                                <PortfolioItem className="portfolioItem3"
                                    thumb={require('../../../assets/img/portfolio/portfolio-1/' + portfolios[3].thumb)}
                                    title={[portfolios[3].title]}
                                />
                                <div className="plusIcon"> </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="tabs-section section pb--120 pb-md--80 pb-sm--60 bg_color--1 detailProjectsWrap " >
                <Container className="tabHide">
                    <Row>
                        <Col xs={12}>
                            <div className="tab vertical verticalTbs">
                                <Tab.Container id="pills-tab" defaultActiveKey="first" className="pills-tab">
                                    <Nav variant="pills" className="flex-md-column tab-nav">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className="firstTabLink">Project 1: Business assessment</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Project 2: Blockchain reengineering</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Project 3: S&Op Planning Services</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <a className="closeProjects"> <span>X CLOSE PROJECTS</span></a>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="bgImageProj bgImageProj1"> 
                                                <h2>Business assessment: <br />Business strategy and implementation road map definition </h2>
                                            </div>

                                            <Row>
                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h3>Business strategy & implementation road map definition </h3>
                                                <p className="blue">We do approach this type of project with a very value-driven and time-focused approach, by conducting short but intensive assessments that provide us with a clear view of the situation and initial view of improvement opportunities and gaps compared to best-in-class organizations. </p>
                                                </Col>
                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h4>STEPS </h4>
                                                <p>We do have expert capabilities with extensive business and technological knowledge, allowing us to, after the initial assessment, get to the desired objectives and definition of the strategy north star and design principles, following the following steps:</p>
                                                <span className="stepsNumber">01</span>
                                                <p>Conduct detailed as-is business understanding sessions, to understand business nature, pain points & challenges and company maturity level compared to industry best practices.</p>
                                                <span className="stepsNumber">02</span>
                                                <p>Define future state business strategy, north star guidelines and associated design principles to follow. Directional, easy to understand and detailed enough statements that will set the company's future strategy.</p>
                                                <span className="stepsNumber">03</span>
                                                <p>Outline detailed recommendations in form of detailed requirements and implementation road map (including any interim state) that will guide the company to achieve the defined business strategy.</p>
                                                <span className="stepsNumber">04</span>
                                                <p>Upon completion, we do also provide, if necessary, the road map implementation support to get to the required future state. </p>

                                                </Col>
                                            </Row>

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="bgImageProj bgImageProj2"> 
                                                <h2>Blockchain reengineering </h2>
                                            </div>                                        
                                            <Row>

                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h3>Blockchain reengineering </h3>
                                                <p className="blue">We have a team specialized in implementing blockchain-enhanced operations projects to carry out multiparty process flows.</p>
                                                </Col>
                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h4>SOME OF THE ADVANTAGES </h4>
                                                <p>Blockchain technology provides the opportunity for companies to remove supply chain inefficiencies by reengineering end-to-end processes from customers to strategic partners in a digital twin.  This is achieved by enabling a decentralized management whereby all stakeholders work on the same shared data and workflows recorded on a common and secured blockchain ledger. </p>
                                                <span className="stepsNumber">01</span>
                                                <p>Timestamps in all transactions.</p>
                                                <span className="stepsNumber">02</span>
                                                <p>Real-time collaboration among stakeholders.</p>
                                                <span className="stepsNumber">03</span>
                                                <p>Immutable data that can be corrected but never deleted.</p>
                                                <span className="stepsNumber">04</span>
                                                <p>Shared e-ledger with authorized parties. </p>
                                                <span className="stepsNumber">05</span>
                                                <p>Creation of your digital twin. </p>     
                                                <p> We are a certified Rise-X partner, one of the leading Blockchain Multi-Party platforms for enterprises, and provide responsive implementation capabilities through agile methods in rapid sprints.</p>                                           

                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="bgImageProj bgImageProj3"> 
                                                <h2>S&Op Planning Services: <br />from assessment to operational execution</h2>
                                            </div>

                                            <Row>
                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h3>S&Op Planning Services: from assessment to operational execution </h3>
                                                <p className="blue">Sales and Operations Planning (S&Op) allows, among others, the improvement of sales opportunities, reduction of operating costs and improvements in capital management. </p>
                                                </Col>
                                                <Col xl={6} lg={6} md={12} xs={12} className="projectsColumn">
                                                <h4>STEPS </h4>
                                                <p>This is achieved through the enablement of an efficient operating model, supported by a planning solution and the necessary planning expertise.</p>
                                                <p>We are experts in providing end-to-end services to achieve the best possible outcomes, following the below steps: </p>
                                                <span className="stepsNumber">01</span>
                                                <p>Conduct as-is and gaps analysis to identify existing challenges and assess maturity of the organization.</p>
                                                <span className="stepsNumber">02</span>
                                                <p>Design a tailored S&Op operating model that will suit customer needs including processes, harmonized meeting schedules as well as Roles & Responsibilities.</p>
                                                <span className="stepsNumber">03</span>
                                                <p>Outline functional requirements to meet the designed operating model and proceed with the selection and implementation of the optimal planning solution for our customer.</p>
                                                <span className="stepsNumber">04</span>
                                                <p>Upon completion of implementation, provide the necessary planning expertise as a managed service to run daily execution. </p>

                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



    </div>




    );
};

export default PortfolioOne;
