
import React from 'react';

const IconBoxItemOne = ({data, border, padding, iconType, className, showBtn}) => {
    return (
        <div
            className={`${className} icon-box mt--30 text-center ${border ? '' : 'no-border'} ${!border && padding ? 'with-padding' : ''}`}>
            <div className="inner">
                <h5 className="heading heading-h5">{data.title} <br />{data.title2}</h5>
                <span className="servicesVerticalDevider"> </span>
                <div className="icon">
                    {iconType === 'img' ? (
                        <img src={require('assets/img/' + data.icon)} alt={data.title}/>
                    ) : (
                        <i className={data.iconClass}/>
                    )}
                </div>

                <div className="content">
                    <p className="bk_pra">{data.content}</p>
                </div>                

            </div>
        </div>
        
    );
};

export default IconBoxItemOne;